import '../App.css'
import {NavLink} from  'react-router-dom'
function BlogScreen() {
    return (
        <div >
            <NavBar/>
            <Blog/>
        </div>
    )
}

function NavBar(){
    var nav = <>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="https://smmnw.com">Sai Mg Mg Nyan Win</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav mx-auto">
                        <li className="nav-item">
                            <NavLink to='/' end className="nav-link active" aria-current="page">
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Blog</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </>;
    return nav
}

function Blog(){
    return (
        <section className="blog">
            <div className="container text-center p-3 display-5">
                <p>Launching Soon!!</p>
            </div>
        </section>
    )
}

export default BlogScreen