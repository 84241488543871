import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import PortfolioScreen from "./screens/PortfolioScreen";
import BlogScreen from "./screens/BlogScreen";
import AppwriteContextProvider from "./store/AppwriteContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Analytics} from "@vercel/analytics/react";

function App() {
    const client = new QueryClient()
    return (
        <QueryClientProvider client={client}>
            <AppwriteContextProvider>
                <BrowserRouter>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<PortfolioScreen/>}/>
                            <Route path="/blog" element={<BlogScreen/>}/>
                            <Route path='*' element={<Navigate to='/'/>}/>
                        </Routes>
                    </div>
                    <Analytics/>
                </BrowserRouter>
            </AppwriteContextProvider>
        </QueryClientProvider>)
}

export default App;